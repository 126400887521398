<template>
  <div id="PlayerCafeCourtsideClub">
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="courtside-gradient" />
    <TicketPackages
      :ticketPackagesHeader="ticketPackagesHeader"
      :packages="packages"
      :ticketPackageImages="ticketPackageImages"
      :activeSection="'playerCafe'"
    />
  </div>
</template>

<script>
import HeroCarousel from "@/components/patterns/HeroCarousel";
import { getplayerCafeCarouselData } from "@/data/HeroCarousel/playerCafeCourtsideClub";
import TicketPackages from "@/components/patterns/TicketPackages";
import axios from "axios";

export default {
  name: "PlayerCafeCourtsideClub",
  components: {
    HeroCarousel,
    TicketPackages,
  },
  data() {
    return {
      heroCarouselData: getplayerCafeCarouselData(),
      ticketPackagesHeader: {
        header:
          "<p>Introducing this unique and exclusive hospitality experience at the US Open, Courtside Player Cafe Club. This offering combines prime courtside Arthur Ashe Stadium seating with premier food and beverage service.</p>",
      },
      packages: null,
      ticketPackageImages: [
        "playerClub/img-the-access2.jpg",
        "playerClub/img-the-service.jpg",
        "playerClub/img-the-view.jpg",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    this.purl = this.$store.state.epitch.purl;

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement("script");
    let floodlightTag2 = document.createElement("script");
    floodlightTag.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
        a +
        "?"
    );
    floodlightTag2.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
        b +
        "?"
    );
    document.body.appendChild(floodlightTag);
    document.body.appendChild(floodlightTag2);

    let pages = this.$store.state.epitch.ePitch.pages;
    pages.forEach((page) => {
      if (page.id === "13") {
        this.packages = JSON.parse(page.copy);
      }
    });

    if (this.$store.state.epitch.ePitch.mode === "live") {
      const purl = this.$store.state.epitch.ePitch.purl;
      const epitchUrl = this.$store.state.epitch.url;
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
        method: "page",
        purl: purl,
        presentationId: window.presentationId,
        sessionDbId: window.sessionDbId,
        section: 13,
      });
    }

    let copy = this.$store.state.epitch.ePitch.pages;
    if (typeof copy !== "undefined") {
      copy.forEach((page) => {
        if (page.id === "13") {
          let description = JSON.parse(page.copy);
          let pageDescription = description.pageDescription;
          // if (pageDescription) {
          //   this.ticketPackagesHeader.header = pageDescription;
          // }
          // window.console.log('description', pageDescription)
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#PlayerCafeCourtsideClub {
  display: flex;
  flex-direction: column;
  .courtside-gradient {
    width: 100%;
    height: 17px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #e1e1e1;
  }
  .courtside-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-family: "interstate", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    text-transform: uppercase;
    color: $text-grey;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    margin: 50px 0 0 0;
    span {
      font-weight: 700;
      color: $text-black;
    }
  }
  p {
    font-family: "interstate", sans-serif;
    font-weight: 500;
    color: $text-grey;
    line-height: 35px;
    display: block;
    text-align: center;
    padding: 50px 0;
    width: 80%;
  }
  .postscript {
    font-family: "interstate", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: $text-grey;
    font-style: italic;
    line-height: 35px;
    display: block;
    text-align: center;
  }
}
</style>