export const getplayerCafeCarouselData = () => {
  return [
    {
      carouselImages: [
        "playerClub/Carousel/img-cover.jpg",
        "playerClub/Carousel/img-gallery-2.jpg",
        "playerClub/Carousel/img-gallery-3.jpg",
        "playerClub/Carousel/img-gallery-4.jpg",
        "playerClub/Carousel/img-gallery-5.jpg",
        "playerClub/Carousel/img-gallery-6.jpg",
        "playerClub/Carousel/img-gallery-7.jpg",
        "playerClub/Carousel/img-gallery-8.jpg",
      ],
      title: "Player Cafe",
      subTitle: "Courtside Club",
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //   title: "appointment",
        //   link: "appointment",
        //   icon: "appointment",
        // },
      ],
    },
  ];
};
